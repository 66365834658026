<template>
  <section class="Invoicing_root">
    <div class="Invoicing_payment" v-if="invoice_payment_status">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
    <AppSection title="Invoices" v-else>
      <div slot="content">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </AppSection>
  </section>
</template>

<script>
import AppSection from '@/components/_common/AppSection/AppSection';
import { mapGetters } from 'vuex';
export default {
  components: {
    AppSection,
  },
  computed: { ...mapGetters('invoicing', ['invoice_payment_status']) },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
.Invoicing_root {
  height: $page-container-height;
}
</style>
